import { defineStore } from 'pinia'
import _ from 'lodash'
import { apiStore } from './api'
import { ApplicationFieldType } from '~/models/documents/jDocument'

export const operationsStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'operations',
  // a function that returns a fresh state
  state: () => ({
    operations: [] as any[],
  }),

  // optional getters
  getters: {
    getOperations(): any {
      return this.operations
    },
  },
  // optional actions
  actions: {
    async loadOperations(): Promise<any> {
      const api = apiStore().getApiClient
      let operations = await api.getOperations()

      operations = operations.map((p: any) => {
        p.label = `${p.id} - ${p.name}`
        p.type = ApplicationFieldType.operation
        return p
      })

      operations = _.uniqBy(operations, (e: any) => e.id)

      this.operations = operations
    },
    findAndFormatOperation(id: string) {
      const op = this.findOperationById(id) || this.findOperationByDbId(+id)

      return `${op?.id}${op?.name ? ` - ${op?.name}` : ''}`
    },
    findOperationById(id: string) {
      return this.operations.find((operation: any) => operation?.id === id)
    },
    findOperationByName(name: string) {
      return this.operations.find((operation: any) => operation?.name === name)
    },
    findOperationByDbId(id: number) {
      return this.operations.find((operation: any) => operation?.db_id === id)
    },
  },
})
