import Code from '~icons/mdi/code'
import Replicate from '~icons/material-symbols/copy-all-outline'
import Add from '~icons/material-symbols/add'
import AddAlt from '~icons/material-symbols/add-circle-outline'
import SubtractAlt from '~icons/material-symbols/do-not-disturb-on-outline'
import Alert from '~icons/material-symbols/warning-outline'
import Alarm from '~icons/material-symbols/alarm-outline'
import Analytics from '~icons/material-symbols/analytics-outline'
import Book from '~icons/material-symbols/menu-book-outline'
import Bullhorn from '~icons/material-symbols/volume-mute'
import CenterCircle from '~icons/material-symbols/location-searching'
import Checkmark from '~icons/material-symbols/done'
import CheckmarkDone from '~icons/material-symbols/download-done'
import CheckmarkFilled from '~icons/material-symbols/check-circle'
import Pending from '~icons/material-symbols/pending-outline'
import Checkbox from '~icons/material-symbols/check-box-outline-blank'
import CheckboxChecked from '~icons/material-symbols/select-check-box'
import ChevronDown from '~icons/material-symbols/keyboard-arrow-down'
import ChevronRight from '~icons/material-symbols/chevron-right'
import ChevronLeft from '~icons/material-symbols/chevron-left'
import ChevronUp from '~icons/material-symbols/keyboard-arrow-up'
import CaretDown from '~icons/material-symbols/arrow-drop-down'
import CaretRight from '~icons/material-symbols/arrow-right'
import CaretLeft from '~icons/material-symbols/arrow-left'
import CaretUp from '~icons/material-symbols/arrow-drop-up'
import UnfoldMore from '~icons/material-symbols/unfold-more'
import Close from '~icons/material-symbols/close'
import Badge from '~icons/material-symbols/badge'
import VerifiedUser from '~icons/material-symbols/verified-user'
import UnverifiedUser from '~icons/material-symbols/gpp-bad'
import DocumentAttachment from '~icons/material-symbols/file-present-outline'
import Attachment from '~icons/material-symbols/attachment'
import Backspace from '~icons/material-symbols/backspace-outline'
import Idea from '~icons/material-symbols/lightbulb-outline'
import DocumentTasks from '~icons/material-symbols/task-outline'
import DocumentExport from '~icons/material-symbols/file-save-outline-sharp'
import Export from '~icons/material-symbols/download-sharp'
import Dashboard from '~icons/material-symbols/space-dashboard-outline'
import Flash from '~icons/material-symbols/electric-bolt-outline'
import LowPriority from '~icons/material-symbols/low-priority'
import EdgeEnhancement from '~icons/material-symbols/contrast'
import InProgress from '~icons/material-symbols/clock-loader-40'
import Help from '~icons/material-symbols/help'
import Login from '~icons/material-symbols/login'
import Logout from '~icons/material-symbols/logout'
import MediaLibrary from '~icons/material-symbols/photo-library-outline-rounded'
import Save from '~icons/material-symbols/save-outline'
import Image from '~icons/material-symbols/image-outline'
import DocumentPdf from '~icons/material-symbols/picture-as-pdf-outline'
import RadioButton from '~icons/material-symbols/radio-button-unchecked'
import Circle from '~icons/carbon/radio-button'
import CircleSolid from '~icons/material-symbols/radio-button-unchecked'
import RadioButtonChecked from '~icons/material-symbols/radio-button-checked-outline'
import RecentlyViewed from '~icons/material-symbols/history'
import TrashCan from '~icons/material-symbols/delete-outline'
import Settings from '~icons/material-symbols/settings-outline'
import WarningAlt from '~icons/material-symbols/warning-outline'
import Warning from '~icons/material-symbols/error-outline'
import WarningFilled from '~icons/material-symbols/error'
import Information from '~icons/material-symbols/info-outline'
import UserAvatar from '~icons/material-symbols/account-circle-outline'
import User from '~icons/material-symbols/person-outline'
import Tag from '~icons/material-symbols/label-outline'
import Play from '~icons/material-symbols/play-arrow-outline'
import Menu from '~icons/material-symbols/menu'
import Hashtag from '~icons/material-symbols/tag'
import Edit from '~icons/material-symbols/edit-outline'
import Email from '~icons/material-symbols/mail-outline'
import View from '~icons/material-symbols/visibility-outline'
import ViewOff from '~icons/material-symbols/visibility-off-outline'
import DocumentAdd from '~icons/material-symbols/note-add-outline'
import Search from '~icons/material-symbols/search'
import ArrowRight from '~icons/material-symbols/arrow-right-alt'
import ArrowLeft from '~icons/material-symbols/arrow-left-alt'
import ArrowDown from '~icons/material-symbols/arrow-downward'
import ArrowUp from '~icons/material-symbols/arrow-upward'
import ArrowInsert from '~icons/material-symbols/arrow-top-right-rounded'
import SendAlt from '~icons/material-symbols/send-outline'
import Filter from '~icons/material-symbols/filter-alt-outline'
import ArrowUpRight from '~icons/carbon/arrow-up-right'
import ArrowDownRight from '~icons/carbon/arrow-down-right'
import GroupResource from '~icons/material-symbols/ad-group-outline'
import CircleDash from '~icons/material-symbols/filter-tilt-shift'
import Folder from '~icons/material-symbols/folder-outline'
import Calendar from '~icons/material-symbols/calendar-today-outline'
import Renew from '~icons/material-symbols/sync'
import Document from '~icons/material-symbols/description-outline'
import DocumentUnknown from '~icons/material-symbols/unknown-document-outline'
import Users from '~icons/material-symbols/group-outline'
import Cube from '~icons/material-symbols/deployed-code-outline'
import Industry from '~icons/material-symbols/factory-outline'
import Apps from '~icons/material-symbols/apps'
import Download from '~icons/material-symbols/download'
import Upload from '~icons/material-symbols/upload'
import Barcode from '~icons/material-symbols/barcode'
import Flag from '~icons/material-symbols/flag-outline'
import Identification from '~icons/material-symbols/badge-outline'
import CheckmarkOutline from '~icons/material-symbols/check-circle-outline'
import CloseOutline from '~icons/material-symbols/cancel-outline'
import DashedOutline from '~icons/carbon/circle-dash'
import CarbonMachineLearning from '~icons/material-symbols/smart-toy-outline'
import Screen from '~icons/material-symbols/jamboard-kiosk-outline'
import CloseFilled from '~icons/material-symbols/cancel'
import LicenseDraft from '~icons/material-symbols/edit-document-outline'
import Layers from '~icons/material-symbols/layers-outline'
import PresentationFile from '~icons/material-symbols/jamboard-kiosk-outline'
import Undefined from '~icons/carbon/undefined-filled'
import SkipForward from '~icons/material-symbols/fast-forward-outline'
import WarningHexFilled from '~icons/material-symbols/report'
import PauseOutlineFilled from '~icons/material-symbols/pause-circle'
import LightFilled from '~icons/material-symbols/clear-day-rounded'
import MenuVertical from '~icons/material-symbols/more-vert'
import ChartLine from '~icons/material-symbols/table-chart-view-outline'
import Notification from '~icons/material-symbols/notifications'
import NotificationOutline from '~icons/material-symbols/notifications-outline'
import NotificationUnread from '~icons/material-symbols/notifications-unread-outline'
import Ruler from '~icons/material-symbols/straighten-outline'
import DatabaseError from '~icons/material-symbols/error'
import UserProfile from '~icons/material-symbols/user-attributes-outline'
import Language from '~icons/material-symbols/language'
import UserFollow from '~icons/material-symbols/person-add-outline-rounded'
import Locked from '~icons/material-symbols/lock-outline'
import Timer from '~icons/material-symbols/timer-outline'
import Time from '~icons/material-symbols/schedule-outline'
import SettingsAdjust from '~icons/material-symbols/tune'
import Reset from '~icons/material-symbols/undo'
import CertificateCheck from '~icons/material-symbols/task-outline'
import BookmarkAdd from '~icons/material-symbols/bookmark-add-outline'
import Archive from '~icons/material-symbols/archive-outline'
import Password from '~icons/material-symbols/password'
import UserActivity from '~icons/material-symbols/person'
import SettingsCheck from '~icons/carbon/settings-check'
import ExpandMore from '~icons/material-symbols/expand-more'
import Pin from '~/components/CustomIcons/Pin.vue'
import Unpin from '~/components/CustomIcons/Unpin.vue'
import Percentage from '~icons/material-symbols/percent'
import Sparkles from '~/components/CustomIcons/Sparkles.vue'
import NoData from '~/components/CustomIcons/NoData.vue'
import ArrowExpand from '~icons/material-symbols/open-in-full'
import ArrowCollapse from '~icons/material-symbols/close-fullscreen'
import ListChecked from '~icons/material-symbols/checklist'
import ListBulleted from '~icons/material-symbols/format-list-bulleted'
import ListNumbered from '~icons/material-symbols/format-list-numbered'
import CopyFile from '~icons/material-symbols/file-copy-outline'
import TextFont from '~icons/material-symbols/match-case'
import Camera from '~icons/material-symbols/photo-camera'
import Link from '~icons/material-symbols/link'
import ExternalLink from '~icons/material-symbols/open-in-new-sharp'
import CircleMeasurement from '~icons/carbon/settings-adjust'
import CameraOff from '~icons/material-symbols/no-photography-outline'
import ManageHistory from '~icons/material-symbols/manage-history'
import UserRole from '~icons/material-symbols/person-outline'
import Template from '~icons/material-symbols/dashboard-outline'
import CloudOffline from '~icons/material-symbols/cloud-off-outline'
import CloudMonitoring from '~icons/material-symbols/browse-activity-outline'
import Chat from '~icons/material-symbols/chat-outline'
import Star from '~icons/material-symbols/star-outline'
import StarFilled from '~icons/material-symbols/star'
import ProgressBarRound from '~icons/material-symbols/progress-activity'
import Table from '~icons/material-symbols/table-rows-narrow-outline'
import TreeView from '~icons/material-symbols/account-tree-outline'
import Calculate from '~icons/material-symbols/calculate-outline'
import Chart from '~icons/material-symbols/insert-chart-outline'
import ConveyorBelt from '~icons/material-symbols/conveyor-belt-outline'
import TextBold from '~icons/material-symbols/format-bold'
import TextItalic from '~icons/material-symbols/format-italic'
import TextUnderline from '~icons/material-symbols/format-underlined'
import TextParagraph from '~icons/material-symbols/local-parking'
import TextLeft from '~icons/material-symbols/format-align-left'
import TextCenter from '~icons/material-symbols/format-align-center'
import TextRight from '~icons/material-symbols/format-align-right'
import TextH1 from '~icons/material-symbols/format-h1'
import TextH2 from '~icons/material-symbols/format-h2'
import TextH3 from '~icons/material-symbols/format-h3'
import Share from '~icons/material-symbols/share-outline'
import Workplace from '~icons/material-symbols/precision-manufacturing-outline'
import Build from '~icons/material-symbols/build-outline'
import SwitchUser from '~icons/material-symbols/swap-horiz'
import ShowChart from '~icons/material-symbols/show-chart'
import Category from '~icons/material-symbols/category-outline'
import Drive from '~icons/material-symbols/swap-driving-apps-wheel-outline'
import CalendarClock from '~icons/material-symbols/calendar-clock-outline'
import Operator from '~icons/material-symbols/engineering-outline'
import PersonEdit from '~icons/material-symbols/person-edit-outline'
import RecordingFilledAlt from '~icons/carbon/recording-filled-alt'
import People from '~icons/material-symbols/supervisor-account-outline'
import DisplaySettings from '~icons/material-symbols/display-settings-outline'
import Visibility from '~icons/material-symbols/visibility'
import MeansOfMeasure from '~icons/material-symbols/architecture'
import ZoomIn from '~icons/material-symbols/zoom-in'
import ZoomOut from '~icons/material-symbols/zoom-out'
import Translate from '~icons/material-symbols/translate'
import Euro from '~icons/material-symbols/euro'
import SpellCheck from '~icons/material-symbols/spellcheck'
import Assignment from '~icons/material-symbols/assignment-outline-sharp'
import ViewTimeline from '~icons/material-symbols/view-timeline-outline'
import Error from '~icons/material-symbols/error'
import BarChart from '~icons/material-symbols/bar-chart'
import TableSplit from '~icons/material-symbols/table-chart-outline'

// Still Carbon: TBD
import Compass from '~icons/carbon/explore'
import WorkflowAutomation from '~icons/material-symbols/cycle'
import Run from '~icons/carbon/run'

// https://api.iconify.design/material-symbols:precision-manufacturing-outline.svg

export enum Icons {
  ERROR = Error,
  BAR_CHART = BarChart,
  VIEW_TIMELINE = ViewTimeline,
  ASSIGNMENT = Assignment,
  SPELLCHECK = SpellCheck,
  EURO = Euro,
  TRANSLATE = Translate,
  MEANS_OF_MEASURE = MeansOfMeasure,
  DISPLAY_SETTINGS = DisplaySettings,
  PEOPLE = People,
  ZOOM_IN = ZoomIn,
  ZOOM_OUT = ZoomOut,
  LOW_PRIORITY = LowPriority,
  DOCUMENT_UNKNOWN = DocumentUnknown,
  IMAGE = Image,
  OPERATOR = Operator,
  PERSON_EDIT = PersonEdit,
  CALENDAR_CLOCK = CalendarClock,
  CIRCLE_SOLID = CircleSolid,
  DRIVE = Drive,
  SHOW_CHART = ShowChart,
  RUN = Run,
  TABLE_SPLIT = TableSplit,
  COMPASS = Compass,
  WORKFLOW_AUTOMATION = WorkflowAutomation,
  EXTERNAL_LINK = ExternalLink,
  TABLE = Table,
  IDEA = Idea,
  BACKSPACE = Backspace,
  ATTACHMENT = Attachment,
  STAR = Star,
  STAR_FILLED = StarFilled,
  CHART = Chart,
  CONVEYOR_BELT = ConveyorBelt,
  TEXT_BOLD = TextBold,
  TEXT_ITALIC = TextItalic,
  TEXT_UNDERLINE = TextUnderline,
  TEXT_PARAGRAPH = TextParagraph,
  TEXT_LEFT = TextLeft,
  TEXT_CENTER = TextCenter,
  TEXT_RIGHT = TextRight,
  TEXT_H1 = TextH1,
  TEXT_H2 = TextH2,
  TEXT_H3 = TextH3,
  MANAGE_HISTORY = ManageHistory,
  RULER = Ruler,
  SHARE = Share,
  PIN = Pin,
  UNPIN = Unpin,
  BULLHORN = Bullhorn,
  ADD = Add,
  CHAT = Chat,
  ALARM = Alarm,
  ANALYTICS = Analytics,
  PLAY = Play,
  ADD_ALT = AddAlt,
  SUBTRACT_ALT = SubtractAlt,
  ALERT = Alert,
  BOOK = Book,
  NOTIFICATION = Notification,
  NOTIFICATION_OUTLINE = NotificationOutline,
  NOTIFICATION_UNREAD = NotificationUnread,
  PROGRESS_BAR_ROUND = ProgressBarRound,
  CENTER_CIRCLE = CenterCircle,
  CHEVRON_DOWN = ChevronDown,
  CHEVRON_RIGHT = ChevronRight,
  CHEVRON_LEFT = ChevronLeft,
  CHEVRON_UP = ChevronUp,
  CARET_DOWN = CaretDown,
  CARET_RIGHT = CaretRight,
  CARET_LEFT = CaretLeft,
  CARET_UP = CaretUp,
  UNFOLDMORE = UnfoldMore,
  CHECKMARK = Checkmark,
  CHECKMARK_DONE = CheckmarkDone,
  CHECKMARK_OUTLINE = CheckmarkOutline,
  CHECKBOX = Checkbox,
  CHECKBOX_CHECKED = CheckboxChecked,
  CLOSE = Close,
  BADGE = Badge,
  VERFIED_USER = VerifiedUser,
  UNVERIFIED_USER = UnverifiedUser,
  CLOUD_MONITORING = CloudMonitoring,
  CLOUD_OFFLINE = CloudOffline,
  DASHBOARD = Dashboard,
  FLAG = Flag,
  DOCUMENT_ATTACHMENT = DocumentAttachment,
  DOCUMENT_TASKS = DocumentTasks,
  DOCUMENT_EXPORT = DocumentExport,
  EXPAND_MORE = ExpandMore,
  EDGE_ENHANCEMENT = EdgeEnhancement,
  FLASH = Flash,
  HELP = Help,
  IDENTIFICATION = Identification,
  IN_PROGRESS = InProgress,
  LOGIN = Login,
  LOGOUT = Logout,
  LIGHT_FILLED = LightFilled,
  MEDIA_LIBRARY = MediaLibrary,
  PERCENTAGE = Percentage,
  RADIO_BUTTON = RadioButton,
  RADIO_BUTTON_CHECKED = RadioButtonChecked,
  RECENTLY_VIEWED = RecentlyViewed,
  PAUSE_OUTLINE_FILLED = PauseOutlineFilled,
  SKIP_FORWARD = SkipForward,
  TREE_VIEW = TreeView,
  SAVE = Save,
  DOCUMENTPDF = DocumentPdf,
  SEND = SendAlt,
  SETTINGS = Settings,
  TRASH_CAN = TrashCan,
  VIEW = View,
  WARNING_ALT = WarningAlt,
  WARNING = Warning,
  WARNING_FILLED = WarningFilled,
  INFORMATION = Information,
  USER_AVATAR = UserAvatar,
  USER_ROLE = UserRole,
  TAG = Tag,
  MENU = Menu,
  HASHTAG = Hashtag,
  EDIT = Edit,
  PENDING = Pending,
  MACHINE = CarbonMachineLearning,
  EMAIL = Email,
  VIEW_OFF = ViewOff,
  DOCUMENT_ADD = DocumentAdd,
  SEARCH = Search,
  ARROW_RIGHT = ArrowRight,
  ARROW_UP_RIGHT = ArrowUpRight,
  ARROW_DOWN_RIGHT = ArrowDownRight,
  ARROW_LEFT = ArrowLeft,
  ARROW_DOWN = ArrowDown,
  ARROW_UP = ArrowUp,
  ARROW_EXPAND = ArrowExpand,
  ARROW_COLLAPSE = ArrowCollapse,
  ARROW_INSERT = ArrowInsert,
  FILTER = Filter,
  GROUP_RESOURCE = GroupResource,
  CIRCLE = Circle,
  CIRCLE_DASH = CircleDash,
  FOLDER = Folder,
  CALENDAR = Calendar,
  USER = User,
  DOCUMENT = Document,
  USERS = Users,
  CUBE = Cube,
  CARBON_GROUP_RESOURCE = GroupResource,
  RENEW = Renew,
  INDUSTRY = Industry,
  BARCODE = Barcode,
  APPS = Apps,
  TEMPLATE = Template,
  MACHINE_LEARNING = CarbonMachineLearning,
  CLOSE_OUTLINE = CloseOutline,
  DASHED_OUTLINE = DashedOutline,
  CHECKMARK_FILLED = CheckmarkFilled,
  CLOSE_FILLED = CloseFilled,
  SCREEN = Screen,
  LICENSE_DRAFT = LicenseDraft,
  LAYERS = Layers,
  EXPORT = Export,
  PRESENTATION_FILE = PresentationFile,
  UNDEFINED = Undefined,
  WARNING_HEX_FILLED = WarningHexFilled,
  USER_PROFILE = UserProfile,
  LANGUAGE = Language,
  LOCKED = Locked,
  RESET = Reset,
  TIMER = Timer,
  TIME = Time,
  MENU_VERTICAL = MenuVertical,
  CHART_LINE = ChartLine,
  DATABASE_ERROR = DatabaseError,
  USER_FOLLOW = UserFollow,
  CERTIFICATE_CHECK = CertificateCheck,
  BOOKMARK_ADD = BookmarkAdd,
  SETTINGS_CHECK = SettingsCheck,
  ARCHIVE = Archive,
  PASSWORD = Password,
  USER_ACTIVITY = UserActivity,
  SPARKLES = Sparkles,
  WORKPLACE = Workplace,
  BUILD = Build,
  SETTINGS_ADJUST = SettingsAdjust,
  LIST_CHECKED = ListChecked,
  LIST_BULLETED = ListBulleted,
  LIST_NUMBERED = ListNumbered,
  COPY_FILE = CopyFile,
  TEXT_FONT = TextFont,
  CAMERA = Camera,
  LINK = Link,
  CIRCLE_MEASUREMENT = CircleMeasurement,
  CAMERA_OFF = CameraOff,
  DOWNLOAD = Download,
  UPLOAD = Upload,
  REPLICATE = Replicate,
  NO_DATA = NoData,
  RECORDING_FILLED_ALT = RecordingFilledAlt,
  USER_SWITCH = SwitchUser,
  CODE = Code,
  CATEGORY = Category,
  CALCULATE = Calculate,
  VISIBILITY = Visibility,
}

export enum MIcons {
  DOCUMENTPDF = 'picture_as_pdf',
  TABLE = 'table_rows_narrow',
  CODE = 'code',
  PRECISION_MANUFACTURING = 'precision_manufacturing',
  CHRONIC = 'chronic',
  SCHEDULE = 'schedule',
  MOTION_PHOTOS_ON = 'motion_photos_on',
  SETTINGS = 'settings',
  ERROR = 'error',
  SMART_TOY = 'smart_toy',
  FEEDBACK = 'feedback',
  STATUS = 'pending',
  ADD = 'add',
  DEPLOYED_CODE = 'deployed_code',
  BARCODE = 'barcode',
  CLOSE = 'close',
  CROSS_TAB = 'table_rows',
  KEYBOARD_DOUBLE_ARROW_DOWN = 'keyboard_double_arrow_down',
  CHEVRON_LEFT = 'chevron_left',
  CHEVRON_RIGHT = 'chevron_right',
  FULL_SCREEN = 'fullscreen',
  FULL_SCREEN_EXIT = 'fullscreen_exit',
  CALENDAR_MONTH = 'calendar_month',
  ACCOUNT_CIRCLE = 'account_circle',
  INCIDENT = 'problem',
  EDIT = 'edit',
  MORE_HORIZ = 'more_horiz',
  PUBLISH = 'publish',
  ARCHIVE = 'archive',
  DOWNLOAD = 'download',
}

export interface Icon {
  name: keyof typeof Icons
  class?: string | Record<string, string>
}
